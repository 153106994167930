import envVars from '@utils/config/env';
import { ModalsProvider } from '@utils/context/modals';
import { getLastClearedDate, setLastClearedDate } from '@utils/localStorage';
import { AppPropsWithProviders } from '@utils/types/nextApp';
import { formatISO, parseISO } from 'date-fns';
import Script from 'next/script';
import React, { PropsWithChildren, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'styles/globals.scss';
import Head from "next/head";

const queryClient = new QueryClient({});
const Noop = ({ children }: PropsWithChildren<any>) => <>{children}</>;

// We need to set this date every time the localstorage needs to be cleared in every browser
const clearDate = parseISO('2022-05-19');

function MyApp({ Component, pageProps }: AppPropsWithProviders) {
  const ComponentSessionProvider = Component.sessionProvider ?? Noop;
  const ListingProvider = Component.listingProvider ?? Noop;
  const getLayout = Component.getLayout ?? ((page) => page);

  const resetLocalStorage = () => {
    localStorage.clear();
    setLastClearedDate(formatISO(new Date()));
    window.location.reload();
  };

  useEffect(() => {
    const lastClearedDate = getLastClearedDate();
    if (lastClearedDate) {
      const clearedDate = parseISO(lastClearedDate);
      if (clearedDate < clearDate) {
        resetLocalStorage();
      }
    } else {
      resetLocalStorage();
    }
  }, []);

  return (
    <>
      <Head>
        <title>Rove Travel</title>
      </Head>
      <ComponentSessionProvider>
        <ListingProvider>
          <QueryClientProvider client={queryClient}>
            <ModalsProvider>
              <Toaster />
              {getLayout(<Component {...pageProps} />)}
            </ModalsProvider>
          </QueryClientProvider>
        </ListingProvider>
      </ComponentSessionProvider>

      <Script
        id="segment-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${envVars.segmentWriteKey}";analytics.SNIPPET_VERSION="4.15.2";
            analytics.load("${envVars.segmentWriteKey}");
            analytics.page();
            }}());
          `,
        }}
      />
    </>
  );
}

export default MyApp;
